import Module from './Module';
import ModuleModal from './ModuleModal';
import ModuleForm from './ModuleForm';

export default class ModuleModalShortForm extends Module {
	constructor(el) {
		super(el);
	}
	
	//-- Methods
	//--------------------------------------------------------------
	init() {
		this.elModalContent = this.dom.el;
		
		this.formModule = new ModuleForm(this.dom.el.find('.form'));
		
		$.extend(this.formModule.dom, {
			modalSuccess: this.dom.el[0].querySelector('.modal-short-form__success'),
			modalFormWrapper: this.dom.el[0].querySelector('.modal-short-form__content-wrapper')
		});

		this.originalShowSuccessFct = this.formModule.showSuccess.bind(this.formModule);
		this.formModule.showSuccess = this.showSuccessModal.bind(this);

		this.formModule.init();

        this.modalModule = new ModuleModal();
		this.modalModule.init(this.elModalContent[0], {
			classes: 'modal-short-form',
			openCallback: this.callbackOpenModal.bind(this),
			closeCallback: this.callbackCloseModal.bind(this)
		});
        
        this.bindEvents();
		setTimeout(() => { this.modalModule.open(); }, 1000);
	}

	bindEvents() {

	}

	showSuccessModal() {
		this.setCookie();		
		this.originalShowSuccessFct();
	}

	callbackOpenModal() {
		// do yout biding here
	}

	callbackCloseModal() {
		this.setCookie();
	}

	setCookie() {
		let cookieName = 'cds-casting-seen-promo-modal';
		var cookieDate = new Date();
		cookieDate.setTime(cookieDate.getTime() + 3153600000000); // add 100 years
		document.cookie = `${cookieName}=true; expires=${cookieDate.toGMTString()}; path=/`;
	}
}
