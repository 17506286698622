import ua from 'ua-parser-js';
import {
    setModuleBg,
    setMissingBackgroundInObject
} from "../helpers/responsiveBg";
import ModuleBootstrapHelper from "../modules/ModuleBootstrapHelper";

/**
 * Class representing the core of a View
 * @export
 * @class View
 */
export default class View {
    constructor(el) {
        this.dom = {
            el: el,
            body: document.querySelector('body'),
            html: document.querySelector('html'),
            site: document.querySelector('#site'),
            w: window,
            breakpoint: {
                xs:  479,
                xsl: 668,
                sm:  768,
                md:  992,
                lg:  1280,
                xl:  1429
            }
        };

        this.ua = new ua().getResult();
    }

    init() {
        this.initBootstrapHelper();
        this.setDeviceSize();
        this.setDeviceType();
        setModuleBg(this.breakpoint);

        window.ua = this.ua;
		
        window.addEventListener(
            "resize",
            _.debounce(() => {
                this.setDeviceSize();
                this.breakpoint = this.bootstrapHelper.breakpoint;
                window.breakpoint = this.bootstrapHelper.breakpoint;
                setModuleBg(this.breakpoint);
            }, 200)
        );
    }

    initBootstrapHelper() {
        this.bootstrapHelper = new ModuleBootstrapHelper();
        this.breakpoint = this.bootstrapHelper.breakpoint;
        window.breakpoint = this.bootstrapHelper.breakpoint;
    }

    setDeviceSize() {
        // IE FIX FOR getComputedStyle
        if (!window.getComputedStyle) {
            window.getComputedStyle = function(el) {
                this.el = el;
                this.getPropertyValue = function(prop) {
                    var re = /(\-([a-z]){1})/g;
                    if (prop == "float") prop = "styleFloat";
                    if (re.test(prop)) {
                        prop = prop.replace(re, function() {
                            return arguments[2].toUpperCase();
                        });
                    }
                    return el.currentStyle[prop] ? el.currentStyle[prop] : null;
                };
                return this;
            };
        }

        var newDevice = window
            .getComputedStyle(document.body, ":after")
            .getPropertyValue("content");

        // IE8 DEFAULT VALUE
        if (!newDevice) {
            newDevice = "xl";
        }

        // IE9-10 REMOVE QUOTE FROM CONTENT STRING
        newDevice = newDevice.replace(/["']/g, "");

        if (newDevice != window.deviceSize) {
            window.deviceSize = newDevice;
            $(document).trigger("newDeviceSize");
        }
    }

    setDeviceType() {
        let OS = this.ua.os.name.split(" ").join("_");
        let browser = this.ua.browser.name.split(" ").join("_");
        
        this.dom.html.className += `${OS} ${browser}`;
    }
}
