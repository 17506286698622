import Module from './Module';

export default class ModuleForm extends Module {
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            select: this.dom.el[0].querySelectorAll('select'),
            btnSubmit: this.dom.el[0].querySelector('.js-submit'),
            checkboxGroup: this.dom.el[0].querySelector('.js-checkbox-required'),
            fieldMsg: this.dom.el[0].querySelector('.js-field-msg'),
            errList: this.dom.el[0].querySelector('.js-form-error-list'),
            customFieldRequired: this.dom.el[0].querySelectorAll('.js-tag-required'),
            modalSuccess: null,
            modalFormWrapper: null
        });
    }

    init() {
        this.initSelect();
        this.handleFocus();
        this.bindEvents();
        this.successPageUrl = this.dom.el[0].dataset.successpage;
    }

    initSelect() {
        Array.prototype.forEach.call(this.dom.select, (value) => {
            let $value = $(value);

            $value.select2({
                width: '100%',
                dropdownParent: $value.parent(),
                minimumResultsForSearch: -1,
                placeholder: value.dataset.placeholder
            });
        });

        if (this.dom.customFieldRequired.length) {
            Array.prototype.forEach.call(this.dom.customFieldRequired, (value) => {
                let labelRequired = value.querySelector('.select2-selection__placeholder');
                labelRequired.innerHTML = labelRequired.innerHTML + ' <span aria-hidden="true">*</span>';
            });
        }
    }

    bindEvents() {
        let inputRequired = this.dom.el[0].querySelectorAll('input, select');
        let checkboxRequired = '';
        let checkboxChecked = [];

        function checkboxArr(value, index, arr) {
            if (value.checked == true) {
                arr.push(value.checked);
            } else {
                let i = arr.indexOf(value);

                if (index > -1) {
                    arr.splice(i, 1);
                }
            }
        }

        if (this.dom.checkboxGroup != null) {
            checkboxRequired = this.dom.checkboxGroup.querySelectorAll('input[type=checkbox]');

            Array.prototype.forEach.call(checkboxRequired, (value, index) => {
                let label = value.parentElement;

                value.addEventListener('click', (e) => {
                    checkboxArr(value, index, checkboxChecked);
                });

                label.addEventListener('keyup', (e) => {
                    if (e.keyCode == 32) {
                        setTimeout(() => {
                            checkboxArr(value, index, checkboxChecked);
                        }, 0);
                    }
                });
            });
        }

        // Set class error on submit
        this.dom.btnSubmit.addEventListener('click', (e) => {
            let errorList = [];

            Array.prototype.forEach.call(inputRequired, (value) => {
                value.setCustomValidity('');

                let inputErrorMessage = document.getElementById(`${value.id}_error`);
                let select2El = value.nextElementSibling;
                let select2Container = select2El.querySelector('.select2-selection');

                if (!value.validity.valid) {
                    value.classList.add('-error');
                    value.setCustomValidity(value.dataset.errorMessage);

                    let inputErrLabel = value.nextElementSibling.innerText.replace(' *', '');
                    errorList.push(`<a class="error-link" href="#${value.id}" data-action="scroll-to">${inputErrLabel}</a>`);

                    this.dom.fieldMsg.setAttribute('tabindex', '0');
                    setTimeout(() => {
                        this.dom.fieldMsg.focus();
                    }, 100);

                    value.setAttribute('aria-describedby', `${value.id}_error`);
                    inputErrorMessage.setAttribute('aria-hidden', false);

                    if (value.nextElementSibling.classList.contains('select2')) {
                        select2Container.setAttribute('aria-labelledby', `${value.id}_error`);
                    }
                } else {
                    value.classList.remove('-error');

                    if (inputErrorMessage != null) {
                        value.setAttribute('aria-describedby', '');
                        inputErrorMessage.setAttribute('aria-hidden', true);

                        if (value.nextElementSibling.classList.contains('select2')) {
                            select2Container.setAttribute('aria-labelledby', `select2-${value.id}-container`);
                        }
                    }
                }
            });

            if (checkboxRequired.length) {
                Array.prototype.forEach.call(checkboxRequired, (value) => {
                    value.classList.add('-error');

                    if (checkboxChecked.length > 0) {
                        value.classList.remove('-error');
                    }
                });

                if (checkboxChecked.length <= 0) {
                    let checkboxLabel = document.getElementById('disciplines');
                    let checkboxErrMsg = checkboxLabel.dataset.errorCheckboxMessage;
                    errorList.push(`<a href="#disciplines" data-action="scroll-to">${checkboxErrMsg}</a>`);
                    checkboxLabel.setAttribute('tabindex', '0');
                } else {
                    document.getElementById('disciplines').setAttribute('tabindex', '-1');
                }

                if (this.dom.el[0].checkValidity() && checkboxChecked.length > 0) {
                    this.dom.fieldMsg.classList.remove('has-error');
                } else {
                    this.dom.fieldMsg.classList.add('has-error');
                }
            } else {
                if (this.dom.el[0].checkValidity()) {
                    this.dom.fieldMsg.classList.remove('has-error');
                } else {
                    this.dom.fieldMsg.classList.add('has-error');
                }
            }

            this.dom.errList.innerHTML = (errorList.length > 0) ? `<li>${errorList.join(',</li> <li>')}</li>` : '';
        });

        this.dom.el.on('submit', (e) => {
            e.preventDefault();

            if (this.dom.checkboxGroup != null) {
                if (e.target.checkValidity() && checkboxChecked.length > 0) {
                    this.validateFormData(e);
                }
            } else {
                if (e.target.checkValidity()) {
                    this.validateFormData(e);
                }
            }
        });

        this.dom.el.on('keyup', (e) => {
            e.preventDefault();

            let activeCheckbox = document.activeElement.querySelector('input[type="checkbox"]');

            if (e.keyCode == 32 && activeCheckbox) {
                return activeCheckbox.checked = !activeCheckbox.checked;
            }
        });

        this.dom.el.on('keydown', (e) => {
            if (e.keyCode == 32 && e.target.classList.contains('form__label')) {
                e.preventDefault();
            }
        });

        // differentiate keyboard and mouse navigation - keyboard handling
        document.addEventListener('keydown', function (e) {
            // Detect keyboard input "Tab"
            if (e.keyCode === 9) {
                this.dom.el[0].classList.add('keyboard-navigation');
            }
        }.bind(this));

        // differentiate keyboard and mouse navigation - mouse handling
        document.addEventListener('mousedown', function (e) {
            this.dom.el[0].classList.remove('keyboard-navigation');
        }.bind(this));

        // Handle scroll-to
        this.dom.el.on('click', '[data-action~="scroll-to"]', this.handleScrollTo.bind(this));
    }

    validateFormData(e) {
        $.ajax({
            type: 'POST',
            url: this.dom.el[0].dataset.api,
            data: this.dom.el.serialize(),
            datatype: 'json',
            context: this
        })
        .done(function (data, textStatus, jqXHR) {
            if (this.dom.modalSuccess) {
                var gtmValues = $('#SuccessPageGtmValues').val().trim()
                    .replace('%applicationid%', null)
                    .replace('%discipline%', null)
                    .replace('%leadid%', null)
                    .replace('%jobtype%', $('#SelectedPersona').val())
                    .replace('%email%', $('#Email').val());
                eval(gtmValues);
                this.showSuccess();
                this.resetControls();
            } else {
                this.setCookies();
                this.resetControls();
                window.location = this.successPageUrl;
            }
        });
    }

    resetControls() {
        this.dom.el[0].reset();
        Array.prototype.forEach.call(this.dom.select, (value) => {
            $(value).selectedIndex = 0;
            $(value).trigger('change');
        });
    }

    showSuccess() {
        this.dom.modalFormWrapper.setAttribute('aria-hidden', 'true');
        this.dom.modalFormWrapper.classList.add('hidden');
        this.dom.modalSuccess.removeAttribute('aria-hidden');
        this.dom.modalSuccess.classList.remove('hidden');
    }

    setCookies() {
        this.setCookieCurrentValue();
        this.setCookieShortForm();
    }

    setCookieCurrentValue() {
        const cookieName = 'cds-casting-newsletter-currentvalue';
        let cookieDate = new Date();
        let cookieDataList = [];
        let diciplineList = [];

        cookieDataList.push('ApplicationId:' + null);

        if (this.dom.checkboxGroup != null) {
            let elCheckboxes = this.dom.checkboxGroup.querySelectorAll('input[type=checkbox]');

            Array.prototype.forEach.call(elCheckboxes, (checkbox) => {
                if (checkbox.checked == true) {
                    diciplineList.push(checkbox.value);
                }
            });
        }

        cookieDataList.push('Discipline:' + diciplineList.join('|'));
        cookieDataList.push('JobType:' + $('#SelectedPersona').val());
        cookieDataList.push('LeadId:' + null);
        cookieDataList.push('Email:' + $('#Email').val());
        cookieDate.setSeconds(cookieDate.getSeconds() + 10);

        document.cookie = `${cookieName}=${cookieDataList.join('$')}; expires=${cookieDate.toGMTString()}; path=/`;
    }

    setCookieShortForm() {
        let cookieName = 'cds-casting-seen-promo-modal';
        var cookieDate = new Date();
        cookieDate.setTime(cookieDate.getTime() + 3153600000000); // add 100 years
        document.cookie = `${cookieName}=true; expires=${cookieDate.toGMTString()}; path=/`;
    }

    handleFocus() {
        let inputTypes = 'input[type=text], input[type=email], input[type=password], input[type=date]';
        let inputs = this.dom.el[0].querySelectorAll(inputTypes);
        let textareas = this.dom.el[0].querySelectorAll('textarea');
        this.handleFocusOnElementsInModeActiveAndHide(inputs);
        this.handleFocusOnElementsInModeActiveAndHide(textareas);
    }

    handleFocusOnElements(elements) {
        Array.prototype.forEach.call(elements, (element) => {
            element.addEventListener('focus', (el) => {
                element.classList.add('is-active');
            });

            element.addEventListener('blur', (el) => {
                if (!el.target.value.length || el.target.value.trim().length === 0) {
                    element.classList.remove('is-active');
                }
            });
        });
    }

    handleFocusOnElementsInModeActiveAndHide(elements) {
        Array.prototype.forEach.call(elements, (element) => {
            element.addEventListener('focus', (el) => {
                element.classList.add('is-active');
                element.classList.remove('hide-label');
            });

            element.addEventListener('blur', (el) => {
                if (!el.target.value.length || el.target.value.trim().length === 0) {
                    element.classList.remove('is-active');
                } else {
                    element.classList.add('hide-label');
                }
            });
        });
    }

    handleScrollTo(e) {
        e.preventDefault();

        let offset = 80;
        let targetElementSelector = e.currentTarget.getAttribute('href');
        let targetElement = this.dom.el[0].querySelector(targetElementSelector);

        if ($(targetElement).is('select')) {
            offset += 52;
        }

        $('html, body').animate({
            scrollTop: ($(targetElement).offset().top - offset)
        }, 200);

        targetElement.focus();
    }
}
