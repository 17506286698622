import Module from './Module';

export default class ModuleHeroVideo extends Module {
	constructor(el) {
		super(el);

		this.el = null;
		this.elVideo = null;
		this.elVideoSource = null;
		this.elControls = null;
		this.elPlayButton = null;
		this.elPlayButtonLabel = null;

		this.playLabelText = null;
		this.pauseLabelText = null;
		this.isPlaying = false;
	}


	init() {
		if (!this.dom.el) return;

		// abort init if on mobile or tablet
		if (ua && ua.device && (ua.device.type === 'mobile' || ua.device.type === 'tablet')) return;

		// test if Module is builded upon jQuery, if so transforms jq selector into vanilla selector
		if (jQuery) {
			this.el = (this.dom.el instanceof jQuery)? this.dom.el[0] : this.dom.el;
		}

		// bind module to "this" in the event-bound functions
		this.handlePlayButtonClick = this.handlePlayButtonClick.bind(this);
		this.autoPauseVideo = this.autoPauseVideo.bind(this);

		// element selectors
		this.elVideo = this.el.querySelector('.hero-video__video-item');
		this.elControls = this.el.querySelector('.hero-video__controls');
		this.elPlayButton  = this.el.querySelector('.hero-video__controls__play-btn');
		this.elPlayButtonLabel  = this.el.querySelector('.hero-video__controls__play-btn__label');

		this.playLabelText = this.elPlayButtonLabel.dataset.labelPlay;
		this.pauseLabelText = this.elPlayButtonLabel.dataset.labelPause;

		this.bindEvent();

		this.setVideoSource();
		this.elControls.classList.remove('hidden');
		this.playVideo();
	}

	bindEvent() {
		this.elPlayButton.addEventListener('click', this.handlePlayButtonClick);
	}

	setVideoSource() {
		this.elVideoSource = document.createElement('source');
		this.elVideoSource.setAttribute('type', 'video/mp4');
		this.elVideoSource.setAttribute('src', this.elVideo.dataset.src);
		this.elVideo.appendChild(this.elVideoSource);
	}

	handlePlayButtonClick(e) {
		if (this.isPlaying) {
			this.pauseVideo();
		} else {
			this.playVideo();
		}
	}

	playVideo() {
		this.isPlaying = true;
		this.elVideo.addEventListener('playing', this.autoPauseVideo);
		this.elVideo.play();
		this.elPlayButton.classList.remove('paused');
		this.elPlayButtonLabel.textContent = this.playLabelText;
	}

	pauseVideo() {
		this.isPlaying = false;
		this.elVideo.removeEventListener('playing', this.autoPauseVideo);
		this.elVideo.pause();
		this.elPlayButton.classList.add('paused');
		this.elPlayButtonLabel.textContent = this.pauseLabelText;
	}

	autoPauseVideo() {
		let videoBoundingRect = this.el.getBoundingClientRect();

		// does not take the height of the header into account
		if (videoBoundingRect.y + videoBoundingRect.height < 0) {
			this.pauseVideo();
		}
	}
}
