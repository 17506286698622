import Module from './Module';

export default class ModuleCarousel extends Module {
    
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            carousel:      this.dom.el.find('.carousel__generic'),
            buttonWrapper: this.dom.el[0].querySelector('.carousel__btn-wrapper')
		});
		
		this.settings = {
			accessibility: false,
            infinite: false,
			arrows: false,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
            mobileFirst: true,
            responsive: [
                {
					breakpoint: this.dom.breakpoint.xs,
					settings: {
						slidesToShow: 2
                	}
                },
                {
					breakpoint: this.dom.breakpoint.xsl,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: this.dom.breakpoint.sm - 1,
					settings: {
						arrows: true,
						appendArrows: this.dom.buttonWrapper,
						slidesToShow: 3
					}
                }
            ]
		}
    }

    init() {
		this.dom.carousel.slick(this.settings);
	
		if(this.dom.w.innerWidth >= this.dom.breakpoint.md) {
			let prevButtonText = this.dom.el[0].querySelector('.slick-prev').innerHTML = this.dom.buttonWrapper.dataset.buttonPrev;
			let nextButtonText = this.dom.el[0].querySelector('.slick-next').innerHTML = this.dom.buttonWrapper.dataset.buttonNext;
		}

		let dotsContainer = this.dom.el[0].querySelector('.slick-dots');
		
		if(dotsContainer){
			dotsContainer.setAttribute('role','presentation');

			let dots = this.dom.el[0].querySelectorAll('.slick-dots button');
	
			[].forEach.call(dots, function(element) {
				element.setAttribute('tabindex','-1');
			});
		}
    }

}
