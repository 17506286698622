import Module from "./Module";

export default class ModuleBanner extends Module {
    constructor(el) {
        super(el);
        $.extend(this.dom,
            {
                retentionKey: this.dom.el.attr('data-retention-key'),
                retentionSession: this.dom.el.attr('data-retention-session'),
                view: document.querySelectorAll("#site")[0],
                mainHeader: document.querySelectorAll("header.main-header")[0],
                closeBtn: this.dom.el.find('.banner__button')[0],
                nav: document.querySelectorAll("header.main-header nav")[0]
            });
    }

    init() {
        this.manageVisibilityByRetention();
        this.bindEvents();
    }

    manageVisibilityByRetention() {
        if (this.getRetentionValueFromStorage() !== 'false') {
            this.dom.el[0].style.display = "block";
            this.dom.el[0].setAttribute("aria-hidden", "false");
            this.managePaddingTop();
        } else {
            this.dom.el[0].setAttribute("aria-hidden", "true");
        }
    }

    getRetentionValueFromStorage() {
        if (!this.dom.retentionKey) return undefined;
        if (this.dom.retentionSession === 'true') {
            return sessionStorage.getItem(this.dom.retentionKey) || 'true';
        }
        return localStorage.getItem(this.dom.retentionKey) || 'true';
    }

    setRetentionOnStorage(value) {
        if (this.dom.retentionSession === 'true') {
            sessionStorage.setItem(this.dom.retentionKey, value);
        } else {
            localStorage.setItem(this.dom.retentionKey, value);
        }
    }

    managePaddingTop() {
        this.dom.view.style.paddingTop = this.dom.mainHeader.clientHeight - this.dom.nav.clientHeight + "px";
    }

    bindEvents() {
        if (this.dom.closeBtn) {
            this.dom.closeBtn.addEventListener('click', () => {
                this.setRetentionOnStorage(false);
                this.dom.el[0].style.display = "none";
                this.dom.el[0].setAttribute("aria-hidden", "true");
                this.managePaddingTop();
            });
        }

        // Listens to custom event debounceResize
        window.addEventListener("debounceResize", () => {
            this.managePaddingTop();
        });
    }

}