import Module from './Module';

export default class ModuleAccordion extends Module {
    
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            toggleAccordionButtons: this.dom.el[0].querySelectorAll('.accordeon__tab-title'),
            allTabContents: this.dom.el[0].querySelectorAll('.accordeon__content')
        });
    }

    init() {
        this.bindEvents();
        this.initFirstAccordionTab(this.dom.allTabContents[0]);
    }

	bindEvents() {
        let thisModule = this;

        [].forEach.call(this.dom.toggleAccordionButtons, function(element) {
            element.addEventListener('click', (e) => {
                thisModule.toggleDropdown(element);
            });

            element.addEventListener('keydown', (e) => {
                if (e.keyCode == thisModule.dom.keyboard.enter || e.keyCode == thisModule.dom.keyboard.spacebar) {
                    e.preventDefault();
                    thisModule.toggleDropdown(element);
                }
            });
        });
    }

    closeAllTabs(element) {        
        [].forEach.call(this.dom.allTabContents, function (tab) {
            tab.parentElement.classList.remove('is-open');
            var tabTitle = tab.parentElement.querySelector('.accordeon__tab-title'); // patch for no content
            if (tabTitle) {
                tabTitle.setAttribute('aria-expanded', 'false');
            }
            tab.setAttribute('aria-hidden','true');
            tab.classList.remove('is-open');
            $(tab).slideUp('linear');
        });
    }

    toggleDropdown(element) {
        let parentTab = element.parentElement.parentElement;
        let content = parentTab.querySelector('.accordeon__content');

        if (!parentTab.classList.contains('is-open')) {
            this.closeAllTabs();

            parentTab.classList.add('is-open');
            content.classList.add('is-open');
            content.setAttribute('aria-hidden','false');
            $(content).slideDown('linear', () => {
                $('html, body').animate({scrollTop: $(parentTab).offset().top - 53}, 500, () => {});
            });

            element.setAttribute('aria-expanded','true');
        } else {
            this.closeAllTabs();
        }
    }

    initFirstAccordionTab(element) {
        $(element).slideDown('linear');
    }
    
}
