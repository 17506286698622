import ManageFocus from "./services/manage-focus";

var Modules = {

    Banner: require('./modules/ModuleBanner'),
	Events: require('./modules/ModuleEvents'),
	EventFilter: require('./modules/ModuleEventFilter'),
	Video: require('./modules/ModuleVideo'),
	Carousel: require('./modules/ModuleCarousel'),
	ImageCarousel: require('./modules/ModuleImageCarousel'),
	HeroVideo: require('./modules/ModuleHeroVideo'),
	Highlights: require('./modules/ModuleHighlightsCarousel'),
	Testimonials: require('./modules/ModuleTestimonialsCarousel'),
	Form: require('./modules/ModuleForm'),
	Dropdown: require('./modules/ModuleDropdown'),
	Accordion: require('./modules/ModuleAccordion'),
	loadMore: require('./modules/ModuleLoadMore'),
	BlogFilter: require('./modules/ModuleBlogFilter'),
	Popup: require('./modules/ModulePopup'),
	Social: require('./modules/ModuleSocial'),
	Modal: require('./modules/ModuleModal'),
	ModalShortForm: require('./modules/ModuleModalShortForm'),
	ModalVideo: require('./modules/ModuleModalVideo'),

	// core modules
	Ajax: require('./modules/ModuleAjax'),
	Gtm: require('./modules/ModuleGtm')

};

// PromoModal: require('./modules/ModulePromoModal'),

var Views = {
	Main: require('./views/View'),
	Home: require('./views/ViewHome'),
	LifeAtCirque: require('./views/ViewLifeAtCirque')
};

// $(function () {
// 	initClasses($('#site'));
// 	ManageFocus();
// });
document.addEventListener("DOMContentLoaded", function(event) {
	initClasses($('#site'));
	ManageFocus();
});

window.initClasses = function (context) {
	_.each(context.find('[data-view]'), function (el) {
		let list = $(el)
			.data('view')
			.split(/\s+/);

		_.each(list, function (name) {
			if (Views[name] !== undefined) {
				new Views[name].default($(el)).init();
			} else {
				new Views['Main'].default($(el)).init();
			}
		});
	});

	_.each(context.find('[data-module]'), function (el) {
		let list = $(el)
			.data('module')
			.split(/\s+/);

		_.each(list, function (name) {
			if (Modules[name] !== undefined) {
				let m = new Modules[name].default($(el));
				m.init();
			}
		});
	});
};
