import Module from './Module';
import ModuleGtm from '../modules/ModuleGtm';

export default class ModuleAjax extends Module {
    constructor(el) {
        super(el);

        //-- Properties
        //--------------------------------------------------------------
        this.settings = {
            data: window.sitecoreRequestObject,
            dataType: 'html',
            method: 'GET'
        };

        this.url = this.dom.el.data('ajax-url');
        this.placeholderSelector = this.dom.el.attr('data-ajax-placeholder');

        if (this.placeholderSelector !== undefined) {
            this.dom.placeholder = this.dom.el.find(this.placeholderSelector);
        } else { /* If no placeholder, insert in el */
            this.dom.placeholder = $('<div/>').appendTo(this.dom.el);
        }
		
		if (this.dom.el.data('item-id') && this.dom.el.data('lang')){
			this.settings.data =  {
								'ItemId': this.dom.el.data('item-id'),
								'Lang': this.dom.el.data('lang')
						      };
		}
			
    }

    //-- Methods
    //--------------------------------------------------------------
    init() {
        if (!!this.url) {
            $.ajax(this.url, this.settings).done((data) => {
                let context = $(data).replaceAll(this.dom.placeholder);
                this.dom.el.trigger('ajax-loaded').addClass('ajax-loaded');
                window.initClasses(context);

                this.gtm = new ModuleGtm(this.dom.el);
                this.gtm.init();
            });
        }
    }

    scrollToLocations() {
        this.dom.page.animate({scrollTop: this.dom.page.find('[data-module="Locations"]').offset().top - 48}, 500);
    }
}
