export default function ManageFocus() {
    let focusable = document.querySelectorAll('button, [href], form, [tabindex]:not([tabindex="-1"])'),
        focusableArray = Array.apply(null, focusable),
        mouseDown = false;

    focusableArray.forEach((elm, i) => {
        elm.addEventListener("mousedown", () => {
            mouseDown = true;
        });
        elm.addEventListener("mouseup", () => {
            mouseDown = false;
        });
        elm.addEventListener("focus", (e) => {
            if (mouseDown) {
                e.currentTarget.blur();
            }
        });
    });
}