import Module from './Module';

export default class ModuleDropdown extends Module {
    
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            toggleDropdown:    this.dom.el[0].querySelector('.dropdown-toggle'),
            dropdownMenu:      this.dom.el[0].querySelector('.dropdown-menu'),
            dropdownMenuItems: this.dom.el[0].querySelectorAll('.dropdown-item')
		});
    }

    init() {
        this.bindEvents();
        
    }

	bindEvents() {
        function toggleDropdown(menu, menuToggle) {

            if(!menu.classList.contains('is-open')) {
                menuToggle.setAttribute('aria-expanded','true')
                menu.classList.toggle('is-open');
                menuToggle.classList.toggle('is-open');
            } else {
                menuToggle.setAttribute('aria-expanded','false')
                menu.classList.toggle('is-open');
                menuToggle.classList.toggle('is-open');
            }
        }

	    function isElementInNodeList(element, nodelist) {
	        for (var item of nodelist) {
	            if (element == item) {
	                return true;
	            }
	        }

	        return false;
	    }

        this.dom.toggleDropdown.addEventListener('click', (e) => {
            e.preventDefault();
            toggleDropdown(this.dom.dropdownMenu, this.dom.toggleDropdown);
        });

        this.dom.toggleDropdown.addEventListener('keyup', (e) => {
            e.preventDefault();

            if (e.keyCode == this.dom.keyboard.spacebar) {
                toggleDropdown(this.dom.dropdownMenu, this.dom.toggleDropdown);
            }
        });

        this.dom.toggleDropdown.addEventListener('keydown', (e) => {
            let isDropdownOpen = this.dom.dropdownMenu.classList.contains('is-open');

            if (e.keyCode == this.dom.keyboard.arrowDown) {
                e.preventDefault(); 
                
                if (isDropdownOpen) {
                    this.dom.dropdownMenuItems[0].focus();
                }
            }
            
            if (e.keyCode == this.dom.keyboard.arrowUp) {
                e.preventDefault();  

                if (isDropdownOpen) {
                    this.dom.dropdownMenuItems[this.dom.dropdownMenuItems.length-1].focus();
                }
            } 
        });

        let thisModule = this;

        [].forEach.call(this.dom.dropdownMenuItems, function(element) {
            
            element.addEventListener('keydown', (e) => {
                let prevEl, nextEl = null;
                let parentPrev = element.parentElement.previousElementSibling;
                let parentNext = element.parentElement.nextElementSibling;

                if (parentPrev) {
                    prevEl = parentPrev.firstElementChild;
                }
                if (parentNext) {
                    nextEl = parentNext.firstElementChild;
                }

                if (e.keyCode == thisModule.dom.keyboard.arrowUp) {
                    thisModule.changeFocus(e, prevEl);
                }

                if (e.keyCode == thisModule.dom.keyboard.arrowDown) {
                    thisModule.changeFocus(e, nextEl);
                }
            });
        });

        this.dom.toggleDropdown.addEventListener('keyup', (e) => {
            e.preventDefault();
        });

        document.addEventListener('focusin', (e) => {
            if (thisModule.dom.dropdownMenu.classList.contains('is-open')) {
                if (e.target != thisModule.dom.toggleDropdown && !isElementInNodeList(e.target, thisModule.dom.dropdownMenuItems)) {
                    thisModule.dom.toggleDropdown.setAttribute('aria-expanded','false');
                    thisModule.dom.dropdownMenu.classList.remove('is-open');
                    thisModule.dom.toggleDropdown.classList.remove('is-open');
                }
            }
        });

        document.addEventListener('click', function(e) {
            if (!e.target.classList.contains('dropdown-toggle')) {
                thisModule.dom.toggleDropdown.setAttribute('aria-expanded','false')
                thisModule.dom.dropdownMenu.classList.remove('is-open');
                thisModule.dom.toggleDropdown.classList.remove('is-open');
            }
        });

    }
    
    changeFocus(event, target) {    
        event.preventDefault();

        return target ? target.focus() : this.dom.toggleDropdown.focus();
    }
    
}
