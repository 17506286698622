import Module from './Module';

export default class ModuleGtm extends Module {
    constructor(el) {
        super(el);
    }

    //-- Methods
    //--------------------------------------------------------------

    init() {
        this.initGtmPage();
        this.initGtmButtons();
    }

    initGtmPage() {
        var isMobile = window.matchMedia("only screen and (max-width: 760px)");

        if (isMobile.matches) {
            if(window.dataLayer && window.dataLayer[0] && window.dataLayer[0].page)
                window.dataLayer[0].page.device = "mobile";
			    document.cookie = "cds-client-device=mobile;path=/";
        } else {
            if(window.dataLayer && window.dataLayer[0] && window.dataLayer[0].page)
                window.dataLayer[0].page.device = "desktop";
				 document.cookie = "cds-client-device=desktop;path=/";
        }	
    };

    initGtmButtons() {

        // =================================================================
        // If modified, please check for the FrontEnd version
        // =================================================================

        var domElements = this.dom.el;
        var propertyName = "ModuleGtm_initGtmButtons_pushEvent_added";

        //addEventToFirstElement("click", pushEvent);
        addEventToControlsOfClass("btn", "click", pushEvent);
        addEventToControlsOfClass("cds-cta", "click", pushEvent);
        addEventToControlsOfClass("gtm", "click", pushEvent);

        function addEventToFirstElement(eventName, eventFunction) {
            var element = domElements[0];
            var elementClassList = element.classList;
            if (elementClassList.contains("gtm") || elementClassList.contains("btn") || elementClassList.contains("cds-cta")) {
                addEventToElement(element, eventName, eventFunction);
            }
        }

        function addEventToControlsOfClass(controlClassName, eventName, eventFunction) {
            var controls = domElements.find("." + controlClassName);
            
            for (var i = 0; i < controls.length; i++) {
                addEventToElement(controls[i], eventName, eventFunction);
            }
        }

        function addEventToElement(element, eventName, eventFunction) {
            if (!element[propertyName]) {
                element.addEventListener(eventName, eventFunction);
                element[propertyName] = true;
            }
        }

        function pushEvent() {
            var eventAttributes = buildTagProperties(this);
            if (eventAttributes) {
                for (var i = 0; i < eventAttributes.length; i++) {
                    window.dataLayer.push(eventAttributes[i]);
                }
            }
        }

        function sleep(seconds){
            var waitUntil = new Date().getTime() + seconds*1000;
            while(new Date().getTime() < waitUntil) true;
        }

        function buildTagProperties(obj) {
            for (var i = 0; i < obj.attributes.length; i++) {
                if (obj.attributes[i].name == "gtm-json") {
                    return cleanJson(obj.attributes[i].value);
                }
            }
        }

        function cleanJson(strJson) {
            var s = strJson.replace(/\\n/g, "\\n")
                .replace(/\\'/g, "\\'")
                .replace(/\\"/g, '\\"')
                .replace(/\\&/g, "\\&")
                .replace(/\\r/g, "\\r")
                .replace(/\\t/g, "\\t")
                .replace(/\\b/g, "\\b")
                .replace(/\\f/g, "\\f");

            // remove non-printable and other non-valid JSON chars
            s = s.replace(/[\u0000-\u0019]+/g, "");
            return JSON.parse(s);

        }

    };

}