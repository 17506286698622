import View from "./View";

export default class ViewLifeAtCirque extends View {
    constructor(el) {
        super(el);
    }

    init() {
        super.init();

        this.bindEvents();
    }

    bindEvents() {
        // alert('test');

        // $('.video.-js-move').insertBefore('.card-list.-js-move-here');
    }
}	