import Module from './Module';

export default class ModuleBlogFilter extends Module {
    
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            select: this.dom.el.find('select'),
            url: this.dom.el[0].getAttribute('data-ajax-url'),
            placeHolder: document.querySelectorAll('.reset-newslist-placeholder')[0]
        });
    }

    init() {
        this.initSelect();
    }

    initSelect() {
        [].forEach.call(this.dom.select, (value, index) => {
            $(value).select2({
                width: '100%',
                dropdownParent: $(value).parent(),
                minimumResultsForSearch: -1,
                placeholder: value.dataset.placeholder
            });
        });

        this.dom.select.on('select2:open', () => {
            this.dom.el.addClass('is-opened');
            let dropdown = this.dom.el.find('.select2-dropdown');
            dropdown.css('height', 0);
            setTimeout(() => {
                dropdown.css('height', 'auto');
                let height = dropdown.outerHeight();
                dropdown
                    //.css('height', 0)
                    .animate({ height: height }, 200, () => {
                        dropdown.css('height', 'auto');
                    });
            }, 0);
        });

        this.dom.select.on('select2:closing', (e) => {
            if (this.isAnimating) return;

            this.isAnimating = true;
            e.preventDefault();
            e.stopPropagation();

            let dropdown = this.dom.el.find('.select2-dropdown');

            this.dom.el.addClass('is-closing');

            setTimeout(() => {
                this.dom.el.removeClass('is-opened is-closing');
            }, 150);

            dropdown.css('height', dropdown.outerHeight())
                .animate({ height: 0 }, 200, () => {
                    this.dom.select.select2('close');
                });
        });

        this.dom.select.on('select2:close', (e) => {
            this.isAnimating = false;
        });

        this.dom.select.on('select2:select', (e) => {
            if (this.dom.placeHolder !== undefined) {
                this.dom.placeHolder.innerHTML = '';
            }

            let filterDisciplineId = e.params.data.element.getAttribute('data-discipline-id');
            this.ajaxPost(1, filterDisciplineId);

            this.dom.el.addClass('is-changed');
        });

        this.dom.select.on('select2:unselect ', () => {
            this.dom.el.removeClass('is-changed');
        });
    }

    ajaxPost(index, disciplineId) {
        $(this.dom.placeHolder).append('<div class="list__spinner spinner spinner-centered"></div>');
        let thisModule = this;

        $.ajax({
            type: 'GET',
            url: this.dom.url + '&pageindex=' + index + '&disciplineFilterId=' + disciplineId,
            dataType: 'html',
            success: function (data) {
                thisModule.dom.placeHolder.innerHTML = data;
            },
            error: function (error) {
                //$(list).find('.spinner').remove();
                console.log(error);
            }

        });
    }
}
