/**
 * Class representing the core of a Module
 * @export
 * @class Module
 */

export default class Module {
    /**
     * Creates an instance of Module.
     * @param {JQuery} el
     * @memberOf Module
     */
    constructor(el) {
        this.dom = {
            el: el,
            body: document.querySelector('body'),
            html: document.querySelector('html'),
            site: document.querySelector('#site'),
            w: window,
            breakpoint: {
                xs:  479,
                xsl: 668,
                sm:  768,
                md:  992,
                lg:  1280,
                xl:  1429
            },
            keyboard: {
                enter:     13,
                spacebar:  32,
                arrowUp:   38,
                arrowDown: 40,
                tab:       9,
                escape:    27
            }
        };
    }

    init() {
        //
    }
}
