import Module from './Module';

export default class ModuleSocial extends Module {
    constructor(el) {
        super(el);

        //-- Properties
        //--------------------------------------------------------------
        $.extend(this.dom, {
			shareButtons: this.dom.el[0].querySelectorAll('.social-share__link')
        });
    }

    //-- Methods
    //--------------------------------------------------------------
    init() {
        this.bindEvents();
    }

    bindEvents() {
		let thisModule = this;
		
		[].forEach.call(this.dom.shareButtons, function(element) {
            element.addEventListener('click', (e) => {
				e.preventDefault();
                thisModule.socialPopup(element.href, element.title);
            });
        });
    }

	socialPopup(href, title, w, h) {
		w = w || '500';
		h = h || '400';
		let y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2)
		let x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2)
		let popupTitle = ((typeof title !== 'undefined') ? title : 'Share');
		let popopParams = 'width=' + w + ',height=' + h + ',top=' + y + ',left=' + x + ',resizable="1"';

		window.open(href, popupTitle, popopParams);
	}
}
