import Module from './Module';

export default class ModuleLoadMore extends Module {
    constructor(el) {
        super(el);

        //-- Properties
        //--------------------------------------------------------------
        $.extend(this.dom, {
            url: this.dom.el.data('ajax-url')
        });
    }

    //-- Methods
    //--------------------------------------------------------------
    init() {
        this.bindEvents();
    }

    bindEvents() {
        document.addEventListener('mousedown', (e) => {
            let el = e.target;

            if (el.dataset.action == 'load-more') {
                let nextPageIndex      = el.dataset.nextPageIndex;
                let filterDisciplineId = el.dataset.disciplineId;
                this.ajaxPost(e, nextPageIndex, filterDisciplineId);
            }
        });

        document.addEventListener('keydown', (e) => {
            let el = e.target;
            if (e.keyCode == this.dom.keyboard.enter) {
                if (el.dataset.action == 'load-more') {
                    let nextPageIndex      = el.dataset.nextPageIndex;
                    let filterDisciplineId = el.dataset.disciplineId;
                    this.ajaxPost(e, nextPageIndex, filterDisciplineId);
                }
            }
        });
    }

    ajaxPost(e, index, disciplineId) {
        let list     = this.dom.el[0];
        let spinner  = document.createElement('div');
        let loadMore = list.querySelector('[data-action="load-more"]');

        spinner.classList.add('spinner', 'spinner-centered');
        loadMore.replaceWith(spinner);
        
        $.ajax({
            type: 'GET',
            url: `${this.dom.url}&pageindex=${index}&disciplineFilterId=${disciplineId}`,
            dataType: 'html',
            success: (data) => {
                let response = document.createElement('div');
                response.innerHTML = data;
                let container = response;

                container.classList.add('container__wrapper');
                spinner.replaceWith(container);

                // ADA - focus first element - keyboard only
                if (e.keyCode == this.dom.keyboard.enter) {
                    let links = container.querySelectorAll('a');
                    links[0].focus();
                }
            },
            error: (error) => {
                list.removeChild(spinner);
                console.log(error);
            }

        });
    }

}
