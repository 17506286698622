import Module from './Module';

export default class ModuleEventFilter extends Module {
    
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            select: this.dom.el.find('select'),
            filterDropdownContainer: this.dom.el[0],
            filterResult: document.querySelector('.event-filter__results'),
            otherFilterDropdowns: document.querySelectorAll('[data-module="EventFilter"] select'),
            filterableElements: document.querySelectorAll('.event, .event__list .event__item')
        });
    }

    init() {
        this.dom.otherFilterDropdowns = [].filter.call(this.dom.otherFilterDropdowns, element => element != this.dom.select[0]);

        this.initSelect();
    }

    initSelect() {
        [].forEach.call(this.dom.select, (value, index) => {
            $(value).select2({
                width: '100%',
                dropdownParent: $(value).parent(),
                minimumResultsForSearch: -1,
                placeholder: value.dataset.placeholder
            });
        });

        this.dom.select.on('select2:open', () => {
            this.dom.el.addClass('is-opened');
            let dropdown = this.dom.el.find('.select2-dropdown');
            dropdown.css('height', 0);
            setTimeout(()=> {
                dropdown.css('height', 'auto');
                let height = dropdown.outerHeight();
                dropdown
                    //.css('height', 0)
                    .animate({height: height}, 200, () => {
                        dropdown.css('height', 'auto');
                    });
            }, 0);
        });

        this.dom.select.on('select2:closing', (e) => {
            if (this.isAnimating) return;

            this.isAnimating = true;
            e.preventDefault();
            e.stopPropagation();

            let dropdown = this.dom.el.find('.select2-dropdown');

            this.dom.el.addClass('is-closing');

            setTimeout(()=> {
                this.dom.el.removeClass('is-opened is-closing');
            }, 150);

            dropdown.css('height', dropdown.outerHeight())
                .animate({height: 0}, 200, () => {
                    this.dom.select.select2('close');
                });
        });

        this.dom.select.on('select2:close', (e) => {
            this.isAnimating = false;
        });

        this.dom.select.on('select2:select', (e) => {

            let filterAttribute = this.dom.filterDropdownContainer.getAttribute('data-filter-attribute');
            let filterResult = this.applyFilter(filterAttribute, e.params.data.element.getAttribute(filterAttribute));
            
            [].forEach.call(this.dom.otherFilterDropdowns, function (element) {
                $(element).val('(none)').trigger('change');
            });

            this.dom.filterResult.innerHTML = '';
            setTimeout(() => { this.dom.filterResult.innerHTML = this.dom.filterResult.getAttribute('data-result-pattern').replace('{0}', filterResult.resultCount); }, 10);

            /*this.dom.select.one('select2:close', (e) => {
                setTimeout(() => { filterResult.firstResult.focus(); }, 0);
            });*/

            this.dom.el.addClass('is-changed');
        });

        this.dom.select.on('select2:unselect ', () => {
            this.dom.el.removeClass('is-changed');
        });
    }

    applyFilter(attributeName, value) {
        let result = {
            firstResult: null,
            resultCount: 0
        };

        [].forEach.call(this.dom.filterableElements, function (element) {
            let attribute = element.getAttribute(attributeName);

            if (attribute.includes(value)) {
                element.classList.remove('filtered-out');
                if (result.firstResult == null && element.classList.contains('event')) {
                    result.firstResult = element;
                }
                if (element.classList.contains('event__item')) {
                    result.resultCount = result.resultCount + 1;
                }
            } else {
                element.classList.add('filtered-out');
            }
        });

        return result;
    }
}
