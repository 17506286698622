export function setModuleBg(b) {
    let breakpoint = b;

    if (["xs", "sm", "md", "lg", "xl"].indexOf(breakpoint) == -1) {
        breakpoint = "xl";
    }

    var nodes = document.querySelectorAll("[data-responsiveimg]");
    nodes = [].slice.call(nodes);

    nodes.forEach((node, i) => {
        let imagesObj = JSON.parse(
            node.getAttribute("data-ResponsiveImg").replace(/'/g, '"')
        );
        node.style.backgroundImage = `url('${imagesObj[breakpoint]}')`;
    });
}

export function setMissingBackgroundInObject() {
    const isDefaultEmpty = imagesObj => {
        if (imagesObj.xl === "") {
            return true;
        }
        return false;
    };

    var nodes = document.querySelectorAll("[data-responsiveimg]");
    nodes = [].slice.call(nodes);

    nodes.forEach((node, i) => {
        var imagesObj = JSON.parse(
            node.getAttribute("data-ResponsiveImg").replace(/'/g, '"')
        );
        var modifiedObj = Object.assign({}, imagesObj);

        // IF DEFAULT EMPTY
        if (isDefaultEmpty(imagesObj))
            console.warn(
                `Please insert at least the XL background-image on the CMS for the ModuleImageSrc`
            );

        if (modifiedObj.xs === "") {
			if (modifiedObj.sm !== "") 
			{
				modifiedObj.xs = imagesObj.sm;
			} else  {
				modifiedObj.xs = imagesObj.xl;
			}
        }

        if (modifiedObj.sm === "") {
            modifiedObj.sm = modifiedObj.xs;
        }

        if (modifiedObj.md === "") {
            modifiedObj.md = imagesObj.xl;
        }

        if (modifiedObj.lg === "") {
            modifiedObj.lg = imagesObj.xl;
        }

        node.setAttribute("data-ResponsiveImg", JSON.stringify(modifiedObj));
    });
}
