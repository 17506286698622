import Module from './Module';
import ModuleModal from './ModuleModal';

export default class ModuleModalVideo extends Module {
	constructor(el) {
		super(el);
    }

    init() {
        this.elModalContent = this.dom.el;

        let url = this.dom.el[0].dataset.modalUrl;
        this.target = this.dom.el[0].dataset.modalContent;
        this.createContent(url, this.target);
        
        this.modalModule = new ModuleModal();
        this.modalModule.init(document.querySelector(`#${this.target}`), {
            classes: 'modal-video',
            trigger: this.dom.el[0],
			openCallback: this.callbackOpenModal.bind(this),
			closeCallback: this.callbackCloseModal.bind(this)
		});
    }



    createContent(url, id) {
        let content = document.createElement("div");
        content.setAttribute("id", id);
        content.classList.add("hidden");
        content.classList.add("modal");

        let iframe = `<iframe id="${id}_iframe" width='100%' height='100%' src="https://www.youtube.com/embed/${url}?enablejsapi=1" frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe>`;

        content.innerHTML = iframe;
        this.dom.el[0].parentNode.insertBefore(content, this.dom.el[0].nextSibling);
    }
    
    callbackOpenModal() {
		this.playVideo();
	}

    callbackCloseModal() {
        this.pauseVideo();
    }

    pauseVideo() {
        document.querySelector(`#${this.target}_iframe`).contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }

    playVideo() {
        document.querySelector(`#${this.target}_iframe`).contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    }
}