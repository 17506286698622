import Module from './Module';

export default class ModuleTestimonialsCarousel extends Module {
    
    constructor(el) {
        super(el);

        $.extend(this.dom, {
			carousel:      this.dom.el.find('.carousel__generic'),
			buttonWrapper: this.dom.el[0].querySelector('.carousel__btn-wrapper')
		});
		
		this.settings = {
			accessibility: false,
            infinite: false,
			arrows: false,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			mobileFirst: true,
            responsive: [
                {
					breakpoint: this.dom.breakpoint.xsl,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: this.dom.breakpoint.sm - 1,
					settings: {
						arrows: true,
						slidesToShow: 3,
						appendArrows: this.dom.buttonWrapper
					}
				}
            ]
		}
    }

    init() {
		this.dom.carousel.slick(this.settings);

		if(this.dom.w.innerWidth >= this.dom.breakpoint.md) {
			let prevButtonText = this.dom.el[0].querySelector('.slick-prev').innerHTML = this.dom.buttonWrapper.dataset.buttonPrev;
			let nextButtonText = this.dom.el[0].querySelector('.slick-next').innerHTML = this.dom.buttonWrapper.dataset.buttonNext;
		}

		this.resetAria();
	}

	resetAria() {
		let dotsContainer = this.dom.el[0].querySelector('.slick-dots');

		if(dotsContainer){
			dotsContainer.setAttribute('role','presentation');
			dotsContainer.setAttribute('tabindex','-1');

			let dots = this.dom.el[0].querySelectorAll('.slick-dots button');
	
			[].forEach.call(dots, function(element) {
				element.setAttribute('tabindex','-1');
			});
		}

		this.resetSlides()

		this.dom.carousel.on('afterChange', (event, slick, currentSlide) => {
			this.resetSlides()
		});
	}

	resetSlides() {
		let slickSlides = this.dom.el[0].querySelectorAll('.slick-slide');

		if(slickSlides){
			[].forEach.call(slickSlides, function(element) {
				element.removeAttribute('aria-hidden');
			});
		}
	}
}
