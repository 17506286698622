import Module from "./Module";

export default class ModulePopup extends Module {
	constructor(el) {
		super(el);
		
		$.extend(this.dom, {
			boxPopup: this.dom.el[0].querySelector('.js-popup'),
			closePopup:    this.dom.el[0].querySelector('.js-popup-close'),
			contentPopup:  this.dom.el[0].querySelector('.js-popup-content')
		});
	}
	
	init() {
		this.settingsOnLoad();
		this.bindEvents();
	}

	bindEvents() {
		this.dom.closePopup.onclick = () => {
			this.settingsClosePopup();
		};

		document.addEventListener('keydown', (e) => {
			if (this.dom.boxPopup.classList.contains('is-open')) {
				if (e.keyCode == this.dom.keyboard.escape) {
					this.settingsClosePopup();
				}
			}

			if (this.dom.contentPopup != null) {
				if (e.keyCode == this.dom.keyboard.tab ) {
					if (e.shiftKey) { /* shift + tab */ 
						if (document.activeElement == this.dom.contentPopup) {
							this.dom.closePopup.focus();
							e.preventDefault();
						}
					} else {
						if (document.activeElement == this.dom.closePopup) {
							this.dom.contentPopup.focus();
							e.preventDefault();
						}
					}
				}
			}
		});
	}

	settingsOnLoad() {
		this.dom.body.classList.add('popup-open');
		this.dom.boxPopup.classList.add('is-open');
		this.dom.boxPopup.setAttribute('aria-modal', 'true');

		// Modal content
		if (this.dom.contentPopup != null) {
			this.dom.contentPopup.focus();
		}

	}

	settingsClosePopup() {
		this.dom.body.classList.remove('popup-open');
		this.dom.boxPopup.classList.remove('is-open');
		this.dom.boxPopup.setAttribute('aria-modal', 'false');
	}
}