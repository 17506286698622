import Module from './Module';

export default class ModuleEvents extends Module {
    
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            carousel:      this.dom.el.find('.event'),
            buttonWrapper: this.dom.el[0].querySelector('.event__btn-wrapper')
        });
    }

    init() {
        this.eventsCarousel();
    }

    eventsCarousel() {
        this.dom.carousel.slick({
            accessibility: true,
            infinite: false,
            arrows: true,
            appendArrows: this.dom.buttonWrapper
        });

        this.setButtonContent('.slick-prev', this.dom.buttonWrapper.dataset.buttonPrev);
        this.setButtonContent('.slick-next', this.dom.buttonWrapper.dataset.buttonNext);

        let prevArrow = this.dom.buttonWrapper.querySelector('.slick-prev');
        let nextArrow = this.dom.buttonWrapper.querySelector('.slick-next');

        if (prevArrow) {
            prevArrow.setAttribute('aria-label', this.dom.buttonWrapper.dataset.buttonPrev);
        }

        if (nextArrow) {
            nextArrow.setAttribute('aria-label', this.dom.buttonWrapper.dataset.buttonNext);
        }
    }

    setButtonContent(buttonSelector, content) {
        let button = this.dom.el[0].querySelector(buttonSelector);
        if (button) {
            button.innerHTML = content;
        }
    }
}
