import Module from './Module';

export default class ModuleVideo extends Module {
    
    constructor(el) {
        super(el);

        $.extend(this.dom, {
			boxModal:   this.dom.el[0].querySelector('.js-modal'),
			openModal:  this.dom.el[0].querySelector('.js-modal-open'),
			closeModal: this.dom.el[0].querySelector('.js-modal-close'),
			embedVideo: this.dom.el[0].querySelector('.js-modal-video'),
			video:      this.dom.el[0].querySelector('.wistia_embed')
		});
    }

    init() {
		this.bindEvents();
	}

	bindEvents() {
		this.dom.openModal.onclick = (event) => {
			this.settingsOpenModal();
		};

		this.dom.closeModal.onclick = (event) => {
			this.settingsCloseModal();
		};

		document.addEventListener('keydown', (event) => {
			if (this.dom.boxModal.classList.contains('is-open')) {
				if (event.keyCode == 27) {
					this.settingsCloseModal();
				}
				
				if (event.key == 'Tab' || event.keyCode == 9 ) {
					if ( event.shiftKey ) /* shift + tab */ {
						if (document.activeElement == this.dom.embedVideo) {
							this.dom.closeModal.focus();
							event.preventDefault();
						}
					} else {
						if (document.activeElement == this.dom.closeModal) {
							this.dom.embedVideo.focus();
							event.preventDefault();
						}
					}
				}
			}
		});
	}

	settingsOpenModal() {
		let video = Wistia.api(this.dom.video.id);
		let gtm = JSON.parse(this.dom.el[0].dataset.gtm);
		
		
		this.dom.body.classList.add('modal-open');
		this.dom.boxModal.style.display = "block";
		this.dom.boxModal.classList.add('is-open');
		this.dom.boxModal.setAttribute('aria-modal', 'true');
		this.dom.boxModal.setAttribute('aria-hidden', 'false');
		this.dom.embedVideo.focus();

		video.bind('percentwatchedchanged', (percent, lastPercent) => {

			// Trigger at 50%
			if (percent >= .51 && lastPercent <= .51) {
				dataLayer.push(gtm[0].gtmEvent50Percent);
			}

			// Trigger at 95%
			if (percent >= 0.95 && lastPercent <= 0.95) {
				dataLayer.push(gtm[0].gtmEvent95Percent);
			}
		});

		video.bind('pause', () => {
			dataLayer.push(gtm[0].gtmEventPaused);
		});

		video.bind('play', () => {
			dataLayer.push(gtm[0].gtmEventPlay);
		});

		let slickTrack = document.querySelector('.slick-list');
		slickTrack.classList.add('no-transform');
	}

	settingsCloseModal() {
		this.dom.body.classList.remove('modal-open');
		this.dom.boxModal.style.display = "none";
		this.dom.boxModal.classList.remove('is-open');
		this.dom.boxModal.setAttribute('aria-modal', 'false');
		this.dom.boxModal.setAttribute('aria-hidden', 'true');
		this.dom.openModal.focus();

		let slickTrack = document.querySelector('.slick-list');
		slickTrack.classList.remove('no-transform');
	}
}
